import { ItemDataType } from "@/types";
import axios from "axios";

type ClassificationResult = {
  data_type: ItemDataType;
  extension: string | null;
};
// /\.(jpeg|jpg|gif|png|svg|webp|bmp|ico|tif|tiff|eps|ai|indd|heif|raw|psd|cr2|nef|orf|sr2)$/i,

const classifyUrl = (url: string): ClassificationResult => {
  const extensionRegexGroups: {
    type: ItemDataType;
    regex: RegExp;
  }[] = [
    {
      type: ItemDataType.IMAGE,
      regex:
        /\.(jpeg|jpg|gif|png|svg|webp|bmp|ico|tif|tiff|eps|ai|indd|heif|raw|psd|cr2|nef|orf|sr2)(\/|\?|$)/i,
    },
    {
      type: ItemDataType.AUDIO,
      regex:
        /\.(mp3|flac|wav|aac|ogg|oga|m4a|aac|aiff|amr|m4a|opus|wma|alac|dss|dvf|m4p|mmf|mpc|msv|ra|rm|tta|vox|weba)$/i,
    },
    {
      type: ItemDataType.VIDEO,
      regex:
        /\.(mp4|webm|ogg|avi|mov|wmv|flv|mkv|3gp|3g2|h264|m4v|mpg|mpeg|rm|swf|vob|mts|m2ts|ts|qt|yuv|rmvb|asf|amv|mpg2)$/i,
    },
    {
      type: ItemDataType.TEXT,
      regex:
        /\.(txt|pdf|doc|docx|odt|rtf|tex|wks|wpd|wps|html|htm|md|odf|xls|xlsx|ppt|pptx|csv|ods|xlr|pages|log|key|odp)$/i,
    },
    {
      type: ItemDataType.CODE,
      regex:
        /\.(py|js|java|c|cpp|cs|h|css|php|swift|go|rb|pl|sh|sql|xml|json|ts|jsx|vue|r|kt|dart|rs|lua|asm|bash|erl|hs|vbs|bat|f|lisp|scala|groovy|ps1)$/i,
    },
  ];

  const matchedGroup = extensionRegexGroups.find((grp) => url.match(grp.regex));

  if (matchedGroup) {
    const match = url.match(matchedGroup.regex)!;
    return { data_type: matchedGroup.type, extension: match[1].toLowerCase() };
  }

  return { data_type: ItemDataType.OTHER, extension: null };
};

const classifySource = (source: string): ClassificationResult => {
  if (source === "BOOKS3") {
    return { data_type: ItemDataType.TEXT, extension: null };
  }

  return { data_type: ItemDataType.OTHER, extension: null };
};

const classifyUrls = (
  urls: string[]
): { data_type: ItemDataType; url: string; extension: string | null }[] => {
  return urls.map((url) => {
    const baseUrl = url.split("?")[0]; // Remove query string

    const classification = classifyUrl(baseUrl);
    return {
      ...classification,
      url,
    };
  });
};

const DataClassifier = {
  classifyUrl,
  classifyUrls,
  classifySource,
};

export default DataClassifier;
