import { SearchType } from "@/types";
import { createSlice } from "@reduxjs/toolkit";

interface SearchState {
  value: string;
  type: SearchType;
  thumb?: string | ArrayBuffer | null;
}

const initialState: SearchState = {
  value: "",
  type: SearchType.NONE,
  thumb: "",
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setCurrentSearch: (state, action) => {
      state.value = action.payload.value;
      state.type = action.payload.type;
      state.thumb = action.payload.thumb;
    },
  },
});

export const { setCurrentSearch } = searchSlice.actions;

export default searchSlice.reducer;
