import mixpanel from "mixpanel-browser";
import * as Sentry from "@sentry/browser";

//eslint-disable-next-line @typescript-eslint/no-explicit-any
const event = (eventName: string, properties: any) => {
  mixpanel.track(eventName, properties);
};

const networkError = (
  errorName: string,
  params: {
    pageName: string;
    errorMessage: any; //eslint-disable-line @typescript-eslint/no-explicit-any
    errorCode: any; //eslint-disable-line @typescript-eslint/no-explicit-any
    statusCode: any; //eslint-disable-line @typescript-eslint/no-explicit-any
  }
) => {
  const { pageName, errorMessage, errorCode, statusCode } = params;

  Sentry.setTag("page", pageName);
  Sentry.setTag("error_code", errorCode);
  Sentry.setTag("status_code", statusCode);

  const scope = new Sentry.Scope();
  Sentry.captureException(new Error(errorName), scope);

  console.log("error network", errorName);

  if (console) {
    // eslint-disable-next-line no-console
    console.error(`NET:${errorName}: ${errorMessage}`);
  }
};

const error = (errorName: string, pageName: string, errorMessage: string) => {
  Sentry.setTag("page", pageName);
  Sentry.setTag("error_message", errorMessage);

  const scope = new Sentry.Scope();
  Sentry.captureException(new Error(errorName), scope);
  console.log("error ", errorName, pageName);

  // eslint-disable-next-line no-console
  if (console && console.error) {
    // eslint-disable-next-line no-console
    console.error(`JS:${errorName}: ${errorMessage}`);
  }
};

const Logging = {
  event,
  networkError,
  error,
};

export default Logging;
