import { createSlice } from "@reduxjs/toolkit";

interface SnackbarSliceSliceState {
  open: boolean;
  message?: string;
  variant: "doNotTrain" | "error" | "removal" | "doNotTrainError" | "";
}

const initialState: SnackbarSliceSliceState = {
  open: false,
  message: undefined,
  variant: "",
};

export const snackBarSlice = createSlice({
  name: "snackBar",
  initialState,
  reducers: {
    setSnackBar: (state, action) => {
      state.open = action.payload.open;
      state.message = action.payload.message;
      state.variant = action.payload.variant;
    },
  },
});

export const { setSnackBar } = snackBarSlice.actions;

export default snackBarSlice.reducer;
