import { AuthPhase, ListTypeId } from "@/types";
import { createSlice } from "@reduxjs/toolkit";

interface AuthState {
  isAuthenticated: boolean;
  authPhase: AuthPhase;
  user: {
    username: string;
    access_token: string;
    id_token: string;
    refresh_token: string;
    session?: string;
    email: string;
  } | null;
  lists?: {
    id: number;
    name: string;
    type_id: ListTypeId;
  }[];
}

const initialState: AuthState = {
  isAuthenticated: false,
  authPhase: AuthPhase.LOGIN,
  user: {
    username: "",
    access_token: "",
    id_token: "",
    refresh_token: "",
    email: "",
  },
  lists: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthenticationPhase: (state, action) => {
      state.authPhase = action.payload;

      state.authPhase === AuthPhase.DONE
        ? (state.isAuthenticated = true)
        : (state.isAuthenticated = false);

      if (state.authPhase === AuthPhase.LOGOUT && state.user !== null) {
        state.isAuthenticated = false;
        state.user = null;
      }
    },
    setUserInformation: (state, action) => {
      state.user = action.payload;
      state.authPhase === AuthPhase.LOGOUT && (state.user = null);
    },

    setListsInformation: (state, action) => {
      state.lists = action.payload;
    },
  },
});

export const {
  setAuthenticationPhase,
  setUserInformation,
  setListsInformation,
} = authSlice.actions;

export default authSlice.reducer;
