import { TypedUseSelectorHook, useSelector } from "react-redux";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import searchReducer from "./Features/searchSlice";
import moreLikeThisReducer from "./Features/moreLikeThisModalSlice";
import authReducer from "./Features/authSlice";
import authModalsReducer from "./Features/authModalsSlice";
import snackBarReducer from "./Features/snackBarSlice";

import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import AsyncStorage from "@react-native-async-storage/async-storage";
import doNotTrainListDataSlice from "./Features/doNotTrainListDataSlice";

const persistConfig = {
  key: "root",
  storage: AsyncStorage,
  blacklist: ["moreLikeThis", "authModals", "snackBar"],
};

const rootReducer = combineReducers({
  search: searchReducer,
  moreLikeThis: moreLikeThisReducer,
  auth: authReducer,
  authModals: authModalsReducer,
  snackBar: snackBarReducer,
  doNotTrainData: doNotTrainListDataSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

const { getState } = store;

export type RootState = ReturnType<typeof getState>;
export type AppDispatch = typeof store.dispatch;

// typed useSelector hook for typescript
// use this instead of useSelector
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
