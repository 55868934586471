export type DomainAutoCompleteResponse = {
  domain: string;
  count: number;
};

export type APIKey = {
  id: number;
  is_active: boolean;
};

export enum ListTypeId {
  DO_NOT_TRAIN = 1,
  OPT_IN = 2,
  NEAR_DUPLICATE = 4,
}

export enum ItemDataType {
  IMAGE = "IMAGE",
  AUDIO = "AUDIO",
  VIDEO = "VIDEO",
  TEXT = "TEXT",
  CODE = "CODE",
  OTHER = "OTHER",
}

export type ListData = {
  created_timestamp: string;
  id: number;
  name: string;
  type_id: ListTypeId;
  user_id: number;
};

export type UserData = {
  username: string;
  access_token: string;
  id_token: string;
  refresh_token: string;
} | null;

export type UserDomain = {
  id: number;
  is_processed: boolean;
  is_reviewed: boolean;
  is_verified: boolean;
  name: string;
  type_id: ListTypeId;
};

export type DomainSearchResult = {
  caption: string;
  id: number;
  url: string;
  isChecked: boolean;
};

export type LiteratureSearchResult = {
  authors: string[];
  description: string;
  file_size_in_bytes: number;
  filename: string;
  identifiers: { ISBN?: string; UUID?: string; TITLE: string };
  language: string;
  optedOut: boolean;
  publication_date: string;
  publisher: string;
  source: string;
  title: string;
  type: ItemDataType;
  url: string | null;
  isChecked: boolean;
};

export enum SearchType {
  TEXT = "TEXT",
  // URL = "URL",
  IMAGE = "IMAGE",
  DOMAIN = "DOMAIN",
  LITERATURE = "LITERATURE",
  NONE = "NONE",
}

export type SearchResultItem = {
  baseUrl: string | null;
  caption: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dims: any; // ask about this, only seeing null for now in the data
  id: number;
  isDuplicate: boolean;
  ranking: number;
  similarity: number;
  url: string;
  urlIsValid: boolean;
  title: string | null;
  authors: string[] | null;
  type: ItemDataType;
  isChecked: boolean;
};

export type MaterializeSearchResultItem = {
  baseUrl: string | null;
  caption: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dims: any; // ask about this, only seeing null for now in the data
  id: number;
  isDuplicate: boolean;
  ranking: number;
  similarity: number;
  url: string;
  urlIsValid: boolean;
  data_type?: ItemDataType;
  type?: ItemDataType;
  isChecked: boolean;
  is_in_dataset?: boolean;
};

export type ListSelectionItem = {
  id: number;
  url: string;
  isChecked: boolean;
  baseUrl?: string | null;
  caption: string | null;
  dataset_id?: number;
  dataset_type_id?: number;
  ranking?: number;
  similarity?: number;
  source?: "EXTENSION" | "LAION-5B" | "BOOKS3";
  sourceId?: number;
  type?: ItemDataType;
  type_id?: number;
  entry?: LiteratureSearchResult;
  data_type?: ItemDataType;
  is_in_dataset?: boolean;
};

// login

export enum AuthPhase {
  LOGIN = "LOGIN",
  SIGNUP = "SIGNUP",
  NEW_PASSWORD = "NEW_PASSWORD",
  FORGOT_PASSWORD_1 = "FORGOT_PASSWORD_1",
  FORGOT_PASSWORD_2 = "FORGOT_PASSWORD_2",
  MFA_CHALLENGE = "MFA_CHALLENGE",
  DONE = "DONE",
  LOGOUT = "LOGOUT",
}

export enum ChallengeType {
  COMPLETE = "COMPLETE",
  PASSWORD = "PASSWORD",
  NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED",
  SOFTWARE_TOKEN_MFA = "SOFTWARE_TOKEN_MFA",
  SRP = "SRP",
  SRP_VERIFY = "SRP_VERIFY",
}

export type SessionChallenge = {
  session?: "";
  access_token?: "";
  id_token?: "";
  refresh_token?: "";
};

export type ResponseType = {
  code?: string | null;
  remedy?: string | null;
  access_token?: string;
  id_token?: string;
  refresh_token?: string;
};
