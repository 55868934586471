import { SearchType } from "@/types";

export const getSearchUrl = (searchType: SearchType, valueToSearch: string) => {
  const typeSpecificUrl = {
    [SearchType.DOMAIN]: `/search/${searchType}?search_domain=${valueToSearch}`,
    [SearchType.TEXT]: `/search/${searchType}?search_text=${valueToSearch}`,
    [SearchType.IMAGE]: `/search/${searchType}`,
    [SearchType.LITERATURE]: `/search/${searchType}?search_literature=${valueToSearch}`,
    [SearchType.NONE]: `/`,
  };

  return typeSpecificUrl[searchType];
};

export const isValidHttpUrl = (string: string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
};

export const checkImgUrl = async (url: string): Promise<string | null> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(url); // Image loaded successfully
    img.onerror = () => resolve(null); // Image failed to load
    img.src = url;
  });
};
