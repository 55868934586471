import axios, { CancelToken } from "axios";
import CryptoJS from "crypto-js";
import {
  ListSelectionItem,
  DomainAutoCompleteResponse,
  DomainSearchResult,
  ItemDataType,
  ListData,
  ListTypeId,
  LiteratureSearchResult,
  MaterializeSearchResultItem,
  SearchResultItem,
  UserDomain,
} from "@/types";
import { isValidHttpUrl } from "@/utils/utils";
import DataClassifier from "./dataClassifier";
import Logging from "@/utils/Logging";

// use idToken for everything here

const PASSTHROUGH_ENDPOINT = process.env.NEXT_PUBLIC_PASSTHROUGH_ENDPOINT;
const PASSTHROUGH_ENDPOINT_DEV =
  process.env.NEXT_PUBLIC_PASSTHROUGH_ENDPOINT_DEV;

const USER_END_POINT =
  process.env.NODE_ENV === "development"
    ? process.env.NEXT_PUBLIC_HIBT_USER_ENDPOINT_DEV
    : process.env.NEXT_PUBLIC_HIBT_USER_ENDPOINT;

const USER_END_POINT_V2 =
  process.env.NODE_ENV === "development"
    ? process.env.NEXT_PUBLIC_HIBT_USER_ENDPOINT_V2_DEV
    : process.env.NEXT_PUBLIC_HIBT_USER_ENDPOINT_V2;

const search = async (params: {
  CaptchaResponse: string;
  image?: string;
  image_url?: string;
  text?: string;
  skip: number;
  limit: number;
  signal?: AbortSignal;
}) => {
  type Data = {
    records: SearchResultItem[];
    has_more: boolean;
    total: number;
  };

  const data: Data = { records: [], total: 0, has_more: false };
  const dataToSend = {
    CaptchaResponse: params.CaptchaResponse,
    skip: params.skip,
    limit: params.limit,
    text: params.text,
    image_url: params.image_url,
  };

  if (params.image_url) {
    if (isValidHttpUrl(params.image_url)) {
      dataToSend.image_url = params.image_url;
    }

    if (params.image_url?.indexOf("%") === -1) {
      // short logic to only url-encode if it doesn't appear to have been encoded already
      dataToSend.image_url = encodeURI(params.image_url);
    }
  }

  const config = {
    method: "POST",
    url: `${PASSTHROUGH_ENDPOINT}/v2/search/`,
    headers: {
      Accept: "*/*",
      "Accept-Language": "en-US,en;q=0.9",
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
    },
    signal: params.signal,
    data: JSON.stringify(dataToSend),
  };

  await axios(config)
    .then((response) => {
      data.records.push(...response.data.urls);
      data.total = data.records.length;
      data.has_more = response.data.has_more;
    })
    .catch((err) => {
      if (err.code === "ERR_CANCELED") {
        return;
      }

      Logging.error("/search", "HIBTService", err.message);

      if (err.response && err.response.data && err.response.data.detail) {
        throw new Error(err.response.data.detail);
      } else if (err.message) {
        throw new Error(err.message);
      } else {
        throw new Error(
          "An unknown error has occurred. Please try again later."
        );
      }
    });

  return data;
};

async function searchMoreLikeThis(params: {
  CaptchaResponse: string;
  image?: string;
  image_url?: string;
  text?: string;
}) {
  type Data = {
    records: SearchResultItem[];
    total: number;
  };

  const data: Data = { records: [], total: 0 };
  const dataToSend = params;

  if (params.image_url) {
    if (isValidHttpUrl(params.image_url)) {
      dataToSend.image_url = params.image_url;
    }

    if (params.image_url?.indexOf("%") === -1) {
      // short logic to only url-encode if it doesn't appear to have been encoded already
      dataToSend.image_url = encodeURI(params.image_url);
    }
  }

  const config = {
    method: "POST",
    url: `${PASSTHROUGH_ENDPOINT}/v1/moreLikeThis`,
    headers: {
      Accept: "*/*",
      "Accept-Language": "en-US,en;q=0.9",
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
    },
    data: JSON.stringify(dataToSend),
  };

  await axios(config)
    .then((response) => {
      data.records.push(...response.data.records);
      data.total = data.records.length;
    })
    .catch((err) => {
      Logging.error("/moreLikeThis", "HIBTService", err.message);
    });

  return data;
}

async function searchLiterature(params: {
  text: string;
  CaptchaResponse: string;
}) {
  type Data = {
    records: LiteratureSearchResult[];
    total: number;
  };

  const data: Data = { records: [], total: 0 };

  await axios({
    method: "POST",
    url: `${PASSTHROUGH_ENDPOINT}/v1/books/searchResults`,
    data: {
      CaptchaResponse: params.CaptchaResponse,
      text: params.text,
    },
  })
    .then((response) => {
      data.records.push(...response.data);
      data.total = data.records.length;
    })
    .catch((err) => {
      Logging.error("/books/searchResults", "HIBTService", err.message);
    });

  return data;
}

async function searchDomains(
  term: string,
  limit: number,
  skip: number,
  cancelToken?: CancelToken
) {
  type Data = {
    records: DomainAutoCompleteResponse[];
    total: number;
  };
  const data: Data = { records: [], total: 0 };

  try {
    const response = await axios({
      method: "GET",
      url: `${PASSTHROUGH_ENDPOINT}/v1/domains/?term=${term}&limit=${limit}&skip=${skip}`,
      cancelToken,
    });

    data.records.push(...response.data.domains);

    if (
      response.headers["content-range"] !== null &&
      response.headers["content-range"] !== undefined
    ) {
      const contentRange = response.headers["content-range"].split("/");
      const total = contentRange[1];
      data.total = total;
    } else {
      data.total = data.records.length;
    }

    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    if (err.code === "ERR_CANCELED") {
      return;
    }

    Logging.error("/domains", "HIBTService", err.message);
  }

  return data;
}

const getDomainSearchResults = async (params: {
  domain: string;
  limit: number;
  skip: number;
  CaptchaResponse: string;
  signal: AbortSignal;
}) => {
  type Data = {
    domains: DomainSearchResult[];
    has_more: boolean;
    total: number;
  };

  // make domain lowercase
  params.domain = params.domain.toLowerCase();

  const data: Data = { domains: [], total: 0, has_more: false };

  const config = {
    method: "POST",
    url: `${PASSTHROUGH_ENDPOINT}/v2/domain/urls/`,
    headers: {
      Accept: "*/*",
      "Accept-Language": "en-US,en;q=0.9",
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
    },
    signal: params.signal,
    data: JSON.stringify(params),
  };

  await axios(config)
    .then((response) => {
      if (response.data !== null && response.data !== "") {
        data.domains.push(...response.data.urls);
        data.has_more = response.data.has_more;

        // get content range from header
        if (
          response.headers["content-range"] != null &&
          response.headers["content-range"] !== undefined
        ) {
          const contentRange = response.headers["content-range"].split("/");
          const total = contentRange[1];
          data.total = total;
        } else {
          data.total = data.domains.length;
        }
      }
    })
    .catch((err) => {
      if (err.code === "ERR_CANCELED") {
        return;
      }
      Logging.error("/search/urls", "HIBTService", err.message);
      if (err.response && err.response.data && err.response.data.detail) {
        throw new Error(err.response.data.detail);
      } else {
        throw new Error(
          "An unknown error has occurred. Please try again later."
        );
      }
    });

  return data;
};

// retrieve user lists
const getLists = async (idToken: string) => {
  type Data = {
    lists: ListData[];
    status?: number;
  };

  const data: Data = { lists: [] };

  await axios({
    headers: {
      Authorization: idToken,
    },
    withCredentials: true,
    method: "GET",
    url: `${USER_END_POINT}/lists`,
  })
    .then((response) => {
      data.lists.push(...response.data);
    })
    .catch((err) => {
      Logging.error("/lists", "HIBTService", err.message);

      if (err.response?.status === 401 || err.response?.status === 403) {
        data.status = err.response.status;
      }

      throw err;
    });

  return data;
};

type ListSelectionData = {
  records: ListSelectionItem[];
  total: number;
};

// list selections of in list
const getListSelections = async (
  idToken: string,
  list_id: ListTypeId,
  sort?: string,
  range?: string,
  runRecursive = false,
  data?: ListSelectionData
) => {
  const internalData = data || { records: [], total: 0 };

  const maxSize = 1000;
  const pageSize = 25;

  if (sort === null || sort === undefined) {
    sort = '["id", "DESC"]';
  }

  if (range === null || range === undefined) {
    range = `[0, ${pageSize}]`;
  }

  await axios({
    headers: {
      Authorization: idToken,
    },
    withCredentials: true,
    method: "GET",
    url: `${USER_END_POINT}/lists/${list_id}/selections/?sort=${encodeURIComponent(
      sort
    )}&range=${encodeURIComponent(range)}`,
  })
    .then((response) => {
      internalData.records.push(...response.data);
      if (
        response.headers["content-range"] != null &&
        response.headers["content-range"] !== undefined
      ) {
        const contentRange = response.headers["content-range"].split("/");
        const currentRange = contentRange[0].split("-");

        const total = +contentRange[1];
        internalData.total = total;

        if (runRecursive) {
          // if we have all of the results
          if (+currentRange[1] >= total) {
            internalData.total = +total;
            return;
          }

          // if we have more results than the max
          if (+currentRange[1] >= maxSize) {
            internalData.total = +total;
            return;
          }

          return getListSelections(
            idToken,
            list_id,
            sort,
            `[${currentRange[1]}, ${+currentRange[1] + pageSize}]`,
            runRecursive,
            internalData
          );
        }
      }
    })
    .catch((err) => {
      Logging.error("getListSelections", "HIBTService", err.message);
      throw err;
    });

  // if it's an item which is reachable with a URL dirtectly
  const classifiedUrlItems = internalData.records
    .filter((item) => !!item.url)
    .map((item) => ({
      ...item,
      data_type: DataClassifier.classifyUrl(item.url).data_type,
    }))
    .filter((item) => item.data_type !== ItemDataType.CODE); // for now code type is not supported

  // if it's an item which is not opted out by URL
  const classifiedSourceItems = internalData.records
    .filter((item) => item.source === "BOOKS3")
    .map((item) => ({
      ...item,
      data_type: DataClassifier.classifySource(item.source || "").data_type,
    }))
    .filter((item) => item.data_type !== ItemDataType.CODE); // for now code type is not supported

  internalData.records = [...classifiedUrlItems, ...classifiedSourceItems];

  return internalData;
};

// domains
const getAllDomains = async (idToken: string) => {
  type Data = {
    domains: UserDomain[];
    total: number;
  };

  const data: Data = { domains: [], total: 0 };

  await axios({
    headers: {
      Authorization: idToken,
    },
    withCredentials: true,
    method: "GET",
    url: `${USER_END_POINT}/domains`,
  })
    .then((response) => {
      data.domains.push(...response.data.domains);
      if (
        response.headers["content-range"] != null &&
        response.headers["content-range"] !== undefined
      ) {
        const contentRange = response.headers["content-range"].split("/");
        const total = contentRange[1];
        data.total = total;
      } else {
        data.total = data.domains.length;
      }
    })
    .catch((err) => {
      Logging.error("getAllDomains", "HIBTService", err.message);
    });

  return data;
};

const getMaterializeSearchResults = async (
  id: string,
  captchaKey: string,
  salt: string
) => {
  type Data = {
    records: MaterializeSearchResultItem[];
    total: number;
  };

  const data: Data = { records: [], total: 0 };

  const materializeConfig = {
    method: "POST",
    url: `${PASSTHROUGH_ENDPOINT}/v1/materialize`,
    headers: {
      Accept: "*/*",
      "Accept-Language": "en-US,en;q=0.9",
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      id,
      CaptchaResponse: captchaKey,
    }),
  };

  await axios(materializeConfig)
    .then((response) => {
      if (response.data !== null && response.data !== "") {
        const urls: string[] = response.data.urls;

        const decryptedItems = urls.map((url) => {
          // replaced 'idx' with '_'
          let decryptedUrl = url;

          if (url !== null && salt !== null) {
            const bytes = CryptoJS.AES.decrypt(url, salt);
            decryptedUrl = bytes.toString(CryptoJS.enc.Utf8);
          }

          return decryptedUrl;
        });

        const mappedUrls = DataClassifier.classifyUrls(decryptedItems);

        const filteredUrls = mappedUrls.filter(
          (item) =>
            item.data_type !== ItemDataType.OTHER &&
            // not supporting code for now but might later
            item.data_type !== ItemDataType.CODE &&
            // not supporting text for now but might later
            item.data_type !== ItemDataType.TEXT &&
            item.data_type !== undefined
        );

        data.total = filteredUrls.length;

        data.records = filteredUrls.map((classifiedUrl, idx) => {
          const item = {
            baseUrl: null,
            caption: null,
            dims: null,
            id: -1,
            keyId: `${classifiedUrl.url}-${idx}`,
            ranking: 0,
            isDuplicate: false,
            similarity: 0,
            url: classifiedUrl.url,
            urlIsValid: true,
            data_type: classifiedUrl.data_type,
            isChecked: false,
          };

          return item;
        });
      }
    })
    .catch((err) => {
      Logging.error("getmaterializeSearchResults", "HIBTService", err.message);
    });

  return data;
};

const listApiKeys = async (idToken: string) => {
  try {
    const response = await axios({
      headers: {
        Authorization: idToken,
      },
      withCredentials: true,
      method: "GET",
      url: `${USER_END_POINT}/profile/apikeys`,
    });

    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    Logging.error("listApiKeys", "HIBTService", error.message);
    throw error;
  }
};

// mutations

const registerDomain = async (
  idToken: string,
  data: { name: string; type_id: ListTypeId }
) => {
  try {
    const response = await axios({
      headers: {
        Authorization: idToken,
      },
      withCredentials: true,
      method: "POST",
      url: `${USER_END_POINT}/domains`,
      data,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

const deleteDomain = async (idToken: string, domainId: number) => {
  return axios({
    headers: {
      Authorization: idToken,
    },
    withCredentials: true,
    method: "DELETE",
    url: `${USER_END_POINT}/domains/${domainId}`,
  });
};

type ObjectData = {
  id: number;
  url: string;
  caption: string | null;
  type_id: ListTypeId;
  list_id?: number;
  source?: "LAION-5B" | "BOOKS3";
  type?: ItemDataType;
};

// submit an opt out request by id for multiple objects
const insertMultipleObjectsToSelections = async (
  idToken: string,
  selectedObjects: ObjectData[]
) => {
  const updates = selectedObjects.map((obj) => ({
    id: obj.id,
    url: obj.url,
    caption: obj.caption,
    type_id: obj.type_id,
    list_id: obj.list_id,
    type: obj.type,
  }));

  return await axios({
    headers: {
      Authorization: idToken,
    },
    withCredentials: true,
    method: "POST",
    url: `${USER_END_POINT_V2}/selections`,
    data: {
      inserts: updates,
    },
  });
};

// submit an opt out request for multiple objects that are not in the dataset (ie materialized results, similar matches)
const insertMultipleNotFoundInDatasetObjectsToSelections = (
  idToken: string,
  selectedObjects: ObjectData[]
) => {
  const inserts = selectedObjects.map((obj) => ({
    id: obj.id,
    url: obj.url,
    caption: obj.caption,
    type_id: obj.type_id,
    source: obj.source,
    type: obj.type,
  }));

  return axios({
    headers: {
      Authorization: idToken,
    },
    withCredentials: true,
    method: "PUT",
    url: `${USER_END_POINT_V2}/selections/`,
    data: {
      inserts,
    },
  });
};

const removeSelections = async (idToken: string, ids: number[]) => {
  return axios({
    headers: {
      Authorization: idToken,
    },
    withCredentials: true,
    method: "DELETE",
    url: `${USER_END_POINT}/selections/`,
    data: { ids },
  });
};

const removeNonUrlSelections = async (
  idToken: string,
  selection_ids: number[]
) => {
  return await axios({
    headers: {
      Authorization: idToken,
    },
    withCredentials: true,
    method: "DELETE",
    url: `${USER_END_POINT_V2}/selections/nonurl/`,
    data: { ids: selection_ids },
  });
};

const createApiKey = async (idToken: string) => {
  try {
    const response = await axios({
      headers: {
        Authorization: idToken,
      },
      withCredentials: true,
      method: "POST",
      url: `${USER_END_POINT}/profile/apikeys`,
    });
    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    Logging.error("createApiKey", "HIBTService", error.message);
    throw error; // This will ensure that the error is propagated to the caller.
  }
};

const deleteApiKey = async (idToken: string, apiKeyId: number) => {
  return await axios({
    headers: {
      Authorization: idToken,
    },
    withCredentials: true,
    method: "DELETE",
    url: `${USER_END_POINT}/profile/apikeys/${apiKeyId}`,
  });
};

// checks

const checkForUrlsFoundInTheDataset = async (
  captchaKey: string,
  urls: string[]
) => {
  return await axios({
    method: "POST",
    url: `${PASSTHROUGH_ENDPOINT}/v2/urls`,
    data: {
      urls,
      CaptchaResponse: captchaKey,
    },
  });
};

const HibtService = {
  searchDomains,
  searchLiterature,
  getDomainSearchResults,
  search,
  searchMoreLikeThis,
  getLists,
  getListSelections,
  getAllDomains,
  getMaterializeSearchResults,
  listApiKeys,

  // mutations
  registerDomain,
  deleteDomain,
  insertMultipleObjectsToSelections,
  insertMultipleNotFoundInDatasetObjectsToSelections,
  removeSelections,
  removeNonUrlSelections,
  createApiKey,
  deleteApiKey,

  // checks
  checkForUrlsFoundInTheDataset,
};

export default HibtService;
