import { createSlice } from "@reduxjs/toolkit";

interface AuthModalsSliceState {
  isLogInModalOpen: boolean;
  isSignUpModalOpen: boolean;
  isForgotPasswordModalOpen: boolean;
}

const initialState: AuthModalsSliceState = {
  isLogInModalOpen: false,
  isSignUpModalOpen: false,
  isForgotPasswordModalOpen: false,
};

export const authModalsSlice = createSlice({
  name: "authModals",
  initialState,
  reducers: {
    setAuthModalsState: (state, action) => {
      state.isLogInModalOpen = action.payload.isLogInModalOpen;
      state.isSignUpModalOpen = action.payload.isSignUpModalOpen;
      state.isForgotPasswordModalOpen =
        action.payload.isForgotPasswordModalOpen;
    },
  },
});

export const { setAuthModalsState } = authModalsSlice.actions;

export default authModalsSlice.reducer;
