import { createSlice } from "@reduxjs/toolkit";

interface MoreLikeThisModalState {
  isOpen: boolean;
  image_url: string;
  caption?: string;
}

const initialState: MoreLikeThisModalState = {
  isOpen: false,
  image_url: "",
};

export const moreLikeThisModalSlice = createSlice({
  name: "moreLikeThis",
  initialState,
  reducers: {
    setMoreLikeThisModalInfo: (state, action) => {
      state.isOpen = action.payload.isOpen;
      state.image_url = action.payload.image_url;
      state.caption = action.payload.caption;
    },
  },
});

export const { setMoreLikeThisModalInfo } = moreLikeThisModalSlice.actions;

export default moreLikeThisModalSlice.reducer;
