import { ListSelectionItem } from "@/types";
import { createSlice } from "@reduxjs/toolkit";

interface DoNotTrainData {
  doNotTrainList: ListSelectionItem[];
}

const initialState: DoNotTrainData = {
  doNotTrainList: [],
};

export const doNotTrainListDataSlice = createSlice({
  name: "doNotTrainData",
  initialState,
  reducers: {
    setDoNotTrainData: (state, action) => {
      state.doNotTrainList = action.payload;
    },
  },
});

export const { setDoNotTrainData } = doNotTrainListDataSlice.actions;

export default doNotTrainListDataSlice.reducer;
